import React, { Component } from 'react';

class InputGroup extends Component {
	render() {
		return (
			<div className="inputGroup">
				<label className="label">{this.props.formLabel}</label>
				<input
					type="text"
					name={this.props.postLabel}
					className="input"
					defaultValue={this.props.formValue}
				/>
			</div>
		);
	}
}

export default InputGroup;
