import React, {Component} from 'react';

class InputSelectGroup extends Component {
	render() {
		if (!this.props.valueArray || !this.props.valueArray.length || this.props.valueArray.length <= 0) {
			return (<div>Attempting to load states for dropdown...</div>);
		}

		let options = [(<option key="0" value="0">No Selection</option>)];

		this.props.valueArray.forEach((option, i) => {
			options.push(
				<option key={i + 1} value={option.id}>
					{option.value}
				</option>
			);
		});

		return (
			<div className="inputGroup" key={this.props.keyName}>
				<label className="label">{this.props.formLabel}</label>
				<select
					name={this.props.postLabel}
					className="input"
					defaultValue={this.props.formValue}
				>
					{options}
				</select>
			</div>
		);
	}
}

export default InputSelectGroup;
