import * as md5 from 'md5';
import kali from 'kali';
import React, { Component } from 'react';
import './App.css';
import Header from './components/Header.js';
import InputGroup from './components/InputGroup.js';
import InputSelectGroup from './components/InputSelectGroup.js';

const API_PREFIX = process.env.REACT_APP_API_PREFIX;

class App extends Component {

	state = {
		update: false,
		states: [],
		sessionKey: `A4p1RFDBqUEWZUYHBFCM-zbXBTm-5zFvk5D9iG-LbdrG4T7aTjX_sbfbAj4I=`,
		apiPrefix: API_PREFIX,
		dataType: `serviceQuotes`,
		data: ``,
		serviceID: '',
		propNum: '',
		totalCost: ``,
		warranty1Cost: ``,
		warranty2Cost: ``,
		warranty3Cost: ``
	}

	componentDidMount = () => {
		let service_id = this.getQueryParam(`service_id`);
		let prop_num = this.getQueryParam(`prop_num`);
		if (service_id && prop_num) {
			service_id = window.atob(service_id);
			service_id = parseInt(service_id, 10);
			this.getQuoteData(service_id, `customersSvcQuotes`, prop_num);
			this.setState({
				serviceID: service_id,
				dataType: `serviceQuotes`
			})

		}
		this.getStatesData();

		if (!service_id) {
			console.error('================= URL param service_id not found =================');
		}

		if (!prop_num) {
			console.error('================= URL param prop_num not found =================');
		}
	}

	md5GetQueryParams = () => {
		let ts = parseInt(new Date().getTime() / 1000, 10);
		let sKey = md5(`${this.state.sessionKey}_${ts}`);
		return `auth_id=${this.state.sessionKey}&auth_key=${sKey}&auth_ts=${ts}`;
	}

	md5PostXAuthHeaders = () => {
		let ts = parseInt(new Date().getTime() / 1000, 10);
		let sKey = md5(`${this.state.sessionKey}_${ts}`);
		let headers = {
			'X-Auth-ID': this.state.sessionKey,
			'X-Auth-Key': sKey,
			'X-Auth-TS': ts
		};
		return headers;
	}

	getStatesData = () => {
		let qps = this.md5GetQueryParams();
		let dataURL = `//${this.state.apiPrefix}/bronco/legacy/customersStatesDropDown/?${qps}`;

		new kali().get(dataURL, {
			success: (_kali, res, contents) => {
				if (contents && contents.data) {
					console.log(contents.data);
					this.setState({
						states: contents.data
					});
				}
			},

			failure: (_kali, err) => {
				console.error('BOOM: Kali says you broke it (this time in States)!!!!');
				console.error(err);
				_kali.retry(5000);
				return this.setState({ errorMsg: err });
			}

		});
	}

	getQueryParam = (param) => {
		let query = window.location.search.substring(1);
		let vars = query.split('&');
		for (let i = 0; i < vars.length; i++) {
			let pair = vars[i].split('=');
			if (pair[0] === param) {
				return pair[1];
			}
		}
		return false;
	}

	getQuoteData = (id, type, prop_num) => {
		let qps = this.md5GetQueryParams();
		let dataURL = `//${this.state.apiPrefix}/bronco/legacy/svcQuoteEntry/${id}/?${qps}&prop_num=${prop_num}`;

		new kali().get(dataURL, {
			success: (_kali, res, contents) => {
				if (contents && contents.data) {
					console.log('kali Success - contents.data below');
					console.log(contents.data);
					this.setState({
						data: contents.data,
						propNum: prop_num
					});

					console.log(`PropNum: ${this.state.propNum}`)
					this.initialWarrantyPrice(this.state.data.svc_quote);
				}
			},

			failure: (_kali, err) => {
				console.error('BOOM: Kali says you broke it!!!!');
				console.error(err);
				_kali.retry(5000);
				return this.setState({ errorMsg: err });
			}

		});
	}

	initialWarrantyPrice = (serviceQuote) => {
		const modelsArray = this.state.data.models;
		let price1, price2, price3;
		modelsArray.forEach((data, i) => {
			const servWar1 = serviceQuote.warranty_1.toLowerCase();
			const servWar2 = serviceQuote.warranty_2.toLowerCase();
			const servWar3 = serviceQuote.warranty_3.toLowerCase();
			if (data.model_id === serviceQuote.model_id_1) {
				if (servWar1 === 'ext3') {
					price1 = parseFloat(data.ext_warranty_3).toFixed(2);
				} else if (servWar1 === 'ext1') {
					price1 = parseFloat(data.ext_warranty_1).toFixed(2);
				} else {
					price1 = '';
				}
			}
			if (data.model_id === serviceQuote.model_id_2) {
				if (servWar2 === 'ext3') {
					price2 = parseFloat(data.ext_warranty_3).toFixed(2);
				} else if (servWar2 === 'ext1') {
					price2 = parseFloat(data.ext_warranty_1).toFixed(2);
				} else {
					price2 = '';
				}
			}
			if (data.model_id === serviceQuote.model_id_3) {
				if (servWar3 === 'ext3') {
					price3 = parseFloat(data.ext_warranty_3).toFixed(2);
				} else if (servWar3 === 'ext1') {
					price3 = parseFloat(data.ext_warranty_1).toFixed(2);
				} else {
					price3 = '';
				}
			}
		})
		this.setState({
			warranty1Cost: price1,
			warranty2Cost: price2,
			warranty3Cost: price3
		});
	}

	printDate = (type) => {
		let d = new Date();
		let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
		let day = d.getDate();
		if (day.toString().length === 1) {
			day = `0${day}`
		}
		const year = d.getFullYear();
		const year2Digit = year.toString().slice(-2);
		if (type === 'today') {
			let returnDate = `${months[d.getMonth()]} ${day}, ${year}`;
			return returnDate;
		} else {
			let quoteDate = `${year2Digit}${d.getMonth() + 1}${day}`;
			return quoteDate;
		}
	}

	headerData = (array) => {
		let quoteNum = this.printDate();
		let results = [<div className="headerNumber" key="headerNum">Service Quote #{this.state.data.property_number}-{quoteNum}</div>];
		array.forEach((data, i) => {
			if (i === 0) { // add label
				results.push(
					<div className="headerSectionLabel" key="headerLabel_1">Prepared for:</div>
				);
			} else if (i === 4) { // add label
				results.push(
					<div className="headerSectionLabel" key="headerLabel_2">Prepared by:</div>
				);
			}
			for (var info in data) {
				if (info === 'value') {
					if (i === 0) { // add bold to first item
						results.push(
							<div className="headerData" key={`headerData_${i}`}>
								<span className="bold">{data[info]}</span>
							</div>
						);
					} else {
						results.push(
							<div className="headerData" key={`headerData_${i}`}>{data[info]}</div>
						);
					}
				}
			}
		})
		return results;
	}

	buildForm = (array) => {
		let results = [];
		array.forEach((data, i) => {
			let group = [];
			for (var info in data) {
				group.push(data[info]);
			}

			if (group[0] === 'State') {
				return results.push(
					<InputSelectGroup
						key={`${group[0]}_${i}`}
						formLabel={group[0]}
						formValue={this.state.data.svc_quote[group[1]]}
						postLabel={group[1]}
						valueArray={this.state.states}
					/>
				);
			}

			results.push(
				<InputGroup
					key={`${group[0]}_${i}`}
					formLabel={group[0]}
					formValue={this.state.data.svc_quote[group[1]]}
					postLabel={group[1]}
				/>
			)
		})
		return results;
	}

	buildHiddenForm = (array) => {
		let results = [];
		array.forEach((data, i) => {
			results.push(<input key={i} name={data.name} value={data.value} type="hidden" />);
		});
		return results;
	}

	validateEntry = () => {
		let validateText = document.getElementById('authName').value;
		let strippedText = validateText.replace(/^\s+/, '').replace(/\s+$/, '');
		if (strippedText.length > 1 && strippedText !== '') {
			document.querySelector('.errorMsg').classList.add('hidden');
			return true;
		} else {
			document.querySelector('.errorMsg').classList.remove('hidden');
			return false;
		}
	}

	submitSend = (e) => {
		e.preventDefault();
		if (this.validateEntry() === false) {
			return;
		}
		var formData = new FormData(document.getElementById('formContainer'));
		console.log('==============formData==============');
		let postObj = {};
		for (let [k, v] of formData.entries()) {
			console.log(`${k} = ${v}`);
			postObj[k] = v;
			if (k === 'ID') {
				postObj[k] = parseInt(v, 10);
			}
			if (k === 'DeletedAt') {
				postObj[k] = null;
			}
		}
		let authHeaders = this.md5PostXAuthHeaders();

		new kali({ body: postObj, headers: authHeaders }).post(`//${this.state.apiPrefix}/bronco/legacy/customersSvcQuotes/`, {
			success: (_kali, res, contents) => {
				this.sendSupportEmail();
			},

			failure: (_kali, err) => {
				console.error(err);
				alert('Issue writing back data, please retry submission');
			}
		});

	}

	sendSupportEmail = () => {
		let subject = `Service Quote #${this.state.serviceID} has been approved`;
		let html_body = `<p>Support,</p><p>Quote #${this.state.serviceID} has been approved for ${this.state.propNum}</p><p><a href='${window.location.href}'>${window.location.href}</a></p><p>DO NOT REPLY, THIS IS AN AUTOMATED MESSAGE</p><p>Thanks,</p><p>Development</p>`;
		let text_body = `Support,\nQuote #${this.state.serviceID} has been approved for ${this.state.propNum}\n${window.location.href}\nDO NOT REPLY, THIS IS AN AUTOMATED MESSAGE\nThanks,\nDevelopment`;
		let to_address = "support@touchsource.com";
		// let to_address = "kelseyr@touchsource.com";
		let sender_address = "do-not-reply@touchsource.com";
		let postObj = {
			html_body,
			text_body,
			sender_address,
			subject,
			to_address
		}

		new kali({ body: postObj }).post(`//${this.state.apiPrefix}/automailer/`, {
			success: (_kali, res, contents) => {
				const message1 = document.getElementById('msg1');
				message1.classList.add('slideUp1');
				const message2 = document.getElementById('msg2');
				message2.classList.add('slideUp2');
			},

			failure: (_kali, err) => {
				console.error(err);
				alert('Issue writing back data, please retry submission');
			}
		});

	}

	dollarString = (num) => {
		let number = parseFloat(num).toFixed(2);
		if (isNaN(parseFloat(num))) {
			console.log(`${number} is Nan Nan Nan Nan Nan Nan`);
			number = 0;
		}
		return number;
	}

	changeWarranty = (warrantyType, cost, warrantyName) => {
		if (warrantyName === 'warranty1Cost') {
			this.setState({
				warranty1Cost: cost
			})
		} else if (warrantyName === 'warranty2Cost') {
			this.setState({
				warranty2Cost: cost
			})
		} else if (warrantyName === 'warranty3Cost') {
			this.setState({
				warranty3Cost: cost
			})
		}
	}


	render() {
		if (!this.state.data) {
			return <div className="App">Waiting for Data...</div>
		}

		let po_number = this.state.data.svc_quote.po_number;
		let authorization_name = this.state.data.svc_quote.authorization_name;
		let customerHeaderData = [
			{ label: 'Company', value: this.state.data.property_name },
			{ label: 'Name', value: this.state.data.svc_quote.contact_name },
			{ label: 'City', value: this.state.data.svc_quote.contact_phone },
			{ label: 'Phone', value: this.state.data.svc_quote.contact_email },
			{ label: 'Email', value: this.state.data.svc_quote.csr },
			{ label: 'Email', value: this.printDate('today') }
		];
		let customerBillData = [
			{ label: 'Company', value: 'invoice_company' },
			{ label: 'Contact', value: 'invoice_contact' },
			{ label: 'Address', value: 'invoice_address' },
			{ label: 'Suite', value: 'invoice_address_2' },
			{ label: 'City', value: 'invoice_city' },
			{ label: 'State', value: 'invoice_state' },
			{ label: 'Zip', value: 'invoice_zip' },
			{ label: 'A/P Contact', value: 'ap_contact' },
			{ label: 'A/P Phone', value: 'ap_phone' }
		];
		let customerShipHeader = 'SHIP TO';;
		let customerShipData = [
			{ label: 'Company', value: 'ship_company' },
			{ label: 'Contact', value: 'ship_contact' },
			{ label: 'Address', value: 'ship_address' },
			{ label: 'Suite', value: 'ship_address_2' },
			{ label: 'City', value: 'ship_city' },
			{ label: 'State', value: 'ship_state' },
			{ label: 'Zip', value: 'ship_zip' }
		];
		let customerItemData = [
			this.state.data.svc_quote.model_id_1,
			this.state.data.svc_quote.product_1_desc,
			this.state.data.svc_quote.product_1_price,
			this.state.data.svc_quote.model_id_2,
			this.state.data.svc_quote.product_2_price,
			this.state.data.svc_quote.product_2_desc,
			this.state.data.svc_quote.model_id_3,
			this.state.data.svc_quote.product_3_desc,
			this.state.data.svc_quote.product_3_price
		];

		let customerHiddenForm = [
			{ name: 'svc_quote_id', value: this.state.data.svc_quote.svc_quote_id },
			{ name: 'project_id', value: this.state.data.svc_quote.project_id },
			{ name: 'create_date', value: this.state.data.svc_quote.create_date },
			{ name: 'csr', value: this.state.data.svc_quote.csr },
			{ name: 'contact_name:', value: this.state.data.svc_quote.contact_name },
			{ name: 'contact_phone', value: this.state.data.svc_quote.contact_phone },
			{ name: 'contact_email', value: this.state.data.svc_quote.contact_email },
			{ name: 'model_id_1', value: this.state.data.svc_quote.model_id_1 },
			{ name: 'ship_type_1', value: this.state.data.svc_quote.ship_type_1 },
			{ name: 'tech_needed', value: this.state.data.svc_quote.tech_needed },
			{ name: 'technician', value: this.state.data.svc_quote.technician },
			{ name: 'tech_rate', value: this.state.data.svc_quote.tech_rate },
			{ name: 'model_id_2', value: this.state.data.svc_quote.model_id_2 },
			{ name: 'ship_type_2', value: this.state.data.svc_quote.ship_type_2 },
			{ name: 'model_id_3', value: this.state.data.svc_quote.model_id_3 },
			{ name: 'ship_type_3', value: this.state.data.svc_quote.ship_type_3 },
			{ name: 'svc_record_id', value: this.state.data.svc_quote.svc_record_id },
			{ name: 'invoice_equal_ship', value: this.state.data.svc_quote.invoice_equal_ship },
			{ name: 'approved_date', value: this.state.data.svc_quote.approved_date },
			{ name: 'ew___exp_date', value: this.state.data.svc_quote.ew___exp_date },
			{ name: 'total_price', value: this.state.data.svc_quote.total_price },
			{ name: 'id_1___price', value: this.state.data.svc_quote.id_1___price },
			{ name: 'id_2___price', value: this.state.data.svc_quote.id_2___price },
			{ name: 'id_3___price', value: this.state.data.svc_quote.id_3___price },
			{ name: 'ID', value: this.state.data.svc_quote.ID },
			{ name: 'CreatedAt', value: this.state.data.svc_quote.CreatedAt },
			{ name: 'UpdatedAt', value: this.state.data.svc_quote.UpdatedAt },
			{ name: 'DeletedAt', value: '' }
		];

		let totalCost = 0;
		let counter = 0;
		let serviceLookup = (num) => {
			let model = [];
			let modelWithWarranty = [];
			const modelsArray = this.state.data.models;
			console.log(`============ Models array ${num} ==============`);
			console.log(modelsArray);
			modelsArray.forEach((data, i) => {
				if (data.model_id == num) { // find matching model record and create line item
					counter++;
					model.push(
						<div key={`${num}_1`} className="item w-20">{data.warranty_model}</div>
					);

					var htmlObject = document.createElement('div');
					htmlObject.innerHTML = data.description;
					let stringWithHtmlInside = { __html: data.description };
					model.push(
						<div key={`${num}_2`} className="item w-65" dangerouslySetInnerHTML={stringWithHtmlInside}></div>
					);

					let itemCost = this.dollarString(data.svc_price);
					let para = ['', ''];
					if (itemCost[0] === '-') {
						itemCost = itemCost.slice(1, itemCost.length);
						para = ['(', ')'];
					}
					model.push(
						<div key={`${num}_3`} className="item ta-c w-15">{para[0]}${itemCost}{para[1]}</div>
					);

					totalCost += parseInt(data.svc_price);
					model = <div className="itemRow" key={`svcModel_${i}`}>{model}</div>;
					modelWithWarranty.push(model); // contains the model line and warranty line below
					const warrantyNum = `warranty_${counter}`;

					if (data.ext_warranty_1 > 0) { // CREATE RADIO OPTIONS: Warranty_1 has a price so warranty_2 and warranty_3 do also
						console.log(`Warranty${counter} = ${this.state.data.svc_quote[warrantyNum]}`); // None, Ext1, Ext3
						// console.log(this.state.data.svc_quote[warrantyNum]);
						const warExtPrice1 = this.dollarString(data.ext_warranty_1);
						const warExtPrice3 = this.dollarString(data.ext_warranty_3);

						let radioState = [];
						// if (this.state.data.svc_quote[warrantyNum].toLowerCase() === 'none' ||
						// 	this.state.data.svc_quote[warrantyNum].toLowerCase() === '0' ||
						// 	this.state.data.svc_quote[warrantyNum].toLowerCase() === '') {
						// 	radioState = [1, 0, 0];
						// } else if (this.state.data.svc_quote[warrantyNum].toLowerCase() === 'ext1') {
						// 	radioState = [0, 1, 0];
						// } else {
						// 	radioState = [0, 0, 1];
						// }


						if (this.state.data.svc_quote[warrantyNum].toLowerCase() === 'ext3') {
							radioState = [0, 0, 1];
						} else if (this.state.data.svc_quote[warrantyNum].toLowerCase() === 'ext1') {
							radioState = [0, 1, 0];
						} else {
							radioState = [1, 0, 0];
						}

						var currentWarranty = `warranty${counter}Cost`;

						let dollarSign = '$';
						if (this.state[currentWarranty] === '') {
							dollarSign = '';
						}

						modelWithWarranty.push( // add warranty line item
							<div className="itemRow" key={`itemRowWarranty_${counter}`}>
								<div className="item w-20">{`${data.warranty_model} Warranty`}</div>
								<div className="item w-65">
									<div className="textLine">
										<input type="radio" className="radio" onClick={() => this.changeWarranty('None', '', currentWarranty)} name={`warranty_${counter}`} value="None" defaultChecked={radioState[0]} />
										<label className="radioLabel">Standard 2-year Hardware Support included</label>
									</div>
									<div className="textLine">
										<input type="radio" className="radio" onClick={() => this.changeWarranty('Ext1', warExtPrice1, currentWarranty)} name={`warranty_${counter}`} value="Ext1" defaultChecked={radioState[1]} />
										<label className="radioLabel">{`Extend Hardware Support to 3 years for $${warExtPrice1}`}</label>
									</div>
									<div className="textLine">
										<input type="radio" className="radio" onClick={() => this.changeWarranty('Ext3', warExtPrice3, currentWarranty)} name={`warranty_${counter}`} value="Ext3" defaultChecked={radioState[2]} />
										<label className="radioLabel">{`Extend Hardware Support to 5 years for $${warExtPrice3}`}</label>
									</div>
								</div>
								<div className="item w-15 ta-c">{dollarSign}{this.state[currentWarranty]}</div>
							</div>
						)
						if (this.state[currentWarranty] !== '') {
							totalCost += parseInt(this.state[currentWarranty]);
						}
					}
				}
			})
			return modelWithWarranty;
		}

		let techCost = 0;
		let serviceTech = () => {
			const svc_quote = this.state.data.svc_quote;
			let record = [
				<div key="tech_1" className="item w-20">Tech Services</div>,
				<div key="tech_2" className="item w-65">On-site hardware installation (one hour maximum)</div>,
				<div key="tech_3" className="item ta-c w-15">${this.dollarString(svc_quote.tech_rate)}</div>
			];

			if (this.state.data.svc_quote.tech_needed === "true") {
				techCost = parseInt(svc_quote.tech_rate);
				return <div className="itemRow" key="techLine">{record}</div>;
			}
		}

		var shippingCost = 0;
		let shippingPrice = () => {
			let cost = 0;
			let canada = false;
			let models = [customerItemData[0], customerItemData[3], customerItemData[6]];
			const modelsArray = this.state.data.models;
			const shipItems = [ // 2Day Canada Ground None 
				this.state.data.svc_quote.ship_type_1,
				this.state.data.svc_quote.ship_type_2,
				this.state.data.svc_quote.ship_type_3
			];

			models.forEach((equip, i) => {
				modelsArray.forEach((data, j) => {
					if (data.model_id == equip) { // find matching model
						console.log(`Model ${data.model_id}`);
						console.log(`2 Day ${data.ship_2_day}, Ground ${data.ship_ground}, Canada ${data.ship_canada}`);
						if (shipItems[i] == '2Day') {
							parseInt(data.ship_2_day) > 0 ? cost += parseInt(data.ship_2_day) : cost += 0;
							console.log(`2Day Price ${parseInt(data.ship_2_day) > 0}`);
						} else if (shipItems[i] == 'Ground') {
							parseInt(data.ship_ground) > 0 ? cost += parseInt(data.ship_ground) : cost += 0;
							console.log(`Ground Price ${parseInt(data.ship_ground) > 0}`);
						} else if (shipItems[i] == 'Canada') {
							parseInt(data.ship_canada) > 0 ? cost += parseInt(data.ship_canada) : cost += 0;
							console.log(`Canada Price ${parseInt(data.ship_canada) > 0}`);
							canada = true;
						}
					}
				})
			})
			shippingCost = cost;
			let message = 'Shipping & Handling';
			if (canada) {
				message = [
					<div>Shipping & Handling</div>,
					<div>Price is for shipping only - client is responsible for all Canadian import duties and taxes.</div>,
					<div>All prices are in $USD</div>
				]
			}

			return (
				<React.Fragment>
					<div className="item w-65 ta-r">{message}</div>
					<div className="item w-15 ta-c">${this.dollarString(cost)}</div>
				</React.Fragment>
			)
		}



		let renderListItems = () => {
			let items = [];
			const models = this.state.data.models;
			totalCost = 0;
			if (!customerItemData[0] === false) {
				items.push(
					serviceLookup(customerItemData[0])
				)
				// console.log('models');
				// console.log(models);
			}

			if (!customerItemData[3] === false) {
				items.push(
					serviceLookup(customerItemData[3])
				)
			}
			if (!customerItemData[6] === false) {
				items.push(
					serviceLookup(customerItemData[6])
				)
			}

			return (
				<React.Fragment>
					<div className="itemsListHeader">
						<div className="w-20">ITEM</div>
						<div className="w-65">DESCRIPTION</div>
						<div className="w-15 ta-c">PRICE</div>
					</div>
					{items}
					{serviceTech()}
					<div className="itemRow">
						<div className="item w-20"></div>
						{shippingPrice()}
					</div>
					<div className="finalRow">
						<div className="paymentMsg fg-1">
							<div className="warn">PLEASE NOTE:</div>
							<div>Depending on your location, sales tax will be added to your invoice.</div>
						</div>
						<div className="total w-15 ta-c">${this.dollarString(totalCost + shippingCost + techCost)}</div>
					</div>
				</React.Fragment>
			)
		}
		return (
			<div className="App">
				<Header
					constructForm={this.buildForm}
					headerData={this.headerData(customerHeaderData)}
				/>
				<form id="formContainer" name="formContainer">
					<div className="form">
						<div className="formHeader">BILL TO</div>
						<div className="formHeader">{customerShipHeader}</div>
						<div className="formColumn">
							{this.buildForm(customerBillData)}
						</div>
						<div className="formColumn">
							{this.buildForm(customerShipData)}
							<div className="specialMessage">
								<i className="fas fa-exclamation-circle"></i>Please update or correct BILLING and SHIPPING information.
							</div>
						</div>
					</div>
					<div className="itemsList">
						{renderListItems()}
					</div>
					<div className="footer">
						<div className="statusBoxContainer">
							<div className="statusBox" id="msg1">
								<div><i className="fas fa-info-circle"></i>Enter your name and click 'I Accept' as your digital signature</div>
							</div>
							<div className="statusBox" id="msg2">
								<div className="approvedMessage"><i className="far fa-thumbs-up"></i>Approved</div>
								<div>THANK YOU. We appreciate your business!</div>
							</div>
						</div>
						<div className="row">
							<div className="inputGroup fg-2">
								<label className="label">PO Number (if applicable)</label>
								<input type="text"
									className="input"
									defaultValue={po_number}
									name="po_number"
								/>
							</div>
							<div className="row fg-3">
								<div className="inputGroup fg-2">
									<label className="label">Enter your name<span className="errorMsg hidden">You must enter a valid name</span></label>
									<input
										type="text"
										className="input"
										defaultValue={authorization_name}
										name="authorization_name"
										id="authName"
									/>
								</div>
								<button className="acceptBtn" onClick={(e) => this.submitSend(e)}><i className="fas fa-pen-alt"></i>I Accept</button>
							</div>
						</div>
					</div>
					<div>{this.buildHiddenForm(customerHiddenForm)}</div>
				</form>
			</div>
		);
	}
}

export default App;
